<template>
    <v-card class="d-flex flex-column align-start pa-4">
    
        <div class="d-flex align-center w-100">
            <v-checkbox
                v-if="isCanEditFiokoDataCollectionSection"
                v-model="localSelected"
                @change="$event => $emit('update:select', { value: !!$event, reportId: localModel.id })"
            ></v-checkbox>
            <h4>Отчет</h4>
            <v-spacer />
            <span v-if="userUpdateBy">Последнее изменение от: {{ userUpdateBy.name }}</span>
        </div>

        <span class="d-flex align-center">
            <v-subheader>Статус</v-subheader>
            <v-chip :color="reportStatuses[localModel.status].color">{{ reportStatuses[localModel.status].text }}</v-chip>
            <v-btn
                v-if="localModel.status === 'done' && isCanEditFiokoDataCollectionSection"
                class="ml-2"
                small
                elevation="0"
                rounded
                @click="returnToRevision(localModel)"
            >
                <v-icon dark class="mr-2">mdi-reload</v-icon> Вернуть на доработку
            </v-btn>
        </span>

        <div class="d-flex align-center">
            <span v-if="groups" class="d-flex align-center mr-8">
                <v-subheader>Данные для класса</v-subheader>
                <v-select
                    v-model="localModel.group_id"
                    :items="groups"
                    :menu-props="{ maxHeight: '500' }"
                    :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled || localModel.status !== 'waiting'"
                    style="max-width: 100px"
                    color="purple darken-2"
                />
            </span>
            <span v-if="teachers && subjects" class="d-flex align-center mr-8">
                <v-subheader>Ответственный</v-subheader>

                <selector-with-filter
                    v-model="localModel.teacher_id"
                    filter-label="Фильтр по предметам"
                    filter-key="subjects"
                    :filter="subjects"
                    :filter-function="customFilterFunction"
                    items-label="Ответственные"
                    :items="responsibleUsers"
                    :default-filter="defaultSubject"
                    :disabled="!isCanEditFiokoDataCollectionSection || totalDisabled"
                >{{ responsible ? responsible.text : 'Выберите' }}</selector-with-filter>
            </span>
        </div>
        
        <div v-if="isCanEditFiokoDataCollectionSection" class="d-flex align-center justify-end w-100">
            <v-btn
                v-if="(currentUser.id === report.teacher_id) && (currentUser.id === localModel.teacher_id)"
                text
                small
                :loading="editButtonLoading"
                class="mr-2"
                color="purple darken-2"
                @click="$emit('click:edit', localModel)"
            >
                <v-icon size="22px">mdi-pencil</v-icon>
                Заполнить
            </v-btn>
            <v-btn
                text
                small
                class="mr-2"
                color="primary"
                :disabled="(!localModel.teacher_id || !localModel.group_id) || totalDisabled"
                @click="updateReport"
            >
                <v-icon>mdi-tag-check-outline</v-icon>
                Назначить
            </v-btn>

            <v-btn
                v-if="[null, 'waiting'].includes(localModel.status)"
                text
                small
                color="red"
                class="mr-2"
                :disabled="totalDisabled"
                @click="dialogs.reportToRemove = localModel.id"
            >
                <v-icon>mdi-trash-can</v-icon>
                Удалить
            </v-btn>

            <confirm-action-with-pass-dialog
                :value="!!dialogs.reportToRemove"
                title="Для подтверждения удаления введите пароль от текущей учетной записи"
                :action="() => removeReport(dialogs.reportToRemove)"
                @change="dialogs.reportToRemove = null"
            />
        </div>
    </v-card>
</template>

<script>
import { reportStatuses } from './dictionaries'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'
import SelectorWithFilter from '@/components/template/SelectorWithFilter.vue'

export default {
    mixins: [isUserCanMixin],
    components: { ConfirmActionWithPassDialog, SelectorWithFilter },
    props: {
        report: { type: Object, required: true },
        selected: { type: Boolean, default: false },
        teachers: { type: Array, default: null },
        groups: { type: Array, default: null },
        disabled: { type: Boolean, default: false },
        editButtonLoading: { type: Boolean, default: false },
        defaultSubject: { type: String, default: null }
    },
    data () {
        return {
            localSelected: false,
            localDisabled: false,
            dialogs: {
                reportToRemove: null
            },
            userUpdateBy: null,
            localModel: null
        }
    },
    computed: {
        reportStatuses () { return reportStatuses },
        subjects () { return this.$store.getters['calendar_event/subjects'] },
        totalDisabled () { return this.disabled || this.localDisabled },
        currentUser () {
            return this.$store.state.user.profile
        },
        responsible () {
            return this.teachers.find(t => t.value === this.localModel.teacher_id)
        },
        responsibleUsers () {
            const rolesToNumber = {
                'admin_vpr': 1,
                'teacher': 0
            }
            return [...this.teachers]
                .sort((a, b) => rolesToNumber[a.role] - rolesToNumber[b.role])
                .map(item => ({
                    ...item,
                    subtext: item.role === 'admin_vpr' ? 'Администратор ВПР' : null
                }))
        }
    },
    watch: {
        selected: {
            immediate: true,
            handler () {
                this.localSelected = this.selected
            }
        }
    },
    created () {
        this.localModel = _.cloneDeep(this.report)
        this.fetchUserUpdatedBy()
    },
    methods: {
        customFilterFunction (item, filterValue) {
            const subjects = item.subjects
            return subjects.includes(filterValue)
        },
        async fetchUserUpdatedBy () {
            if (!this.localModel.updated_by)
                return

            try {
                const { success, error, data } = await this.$store.dispatch('user/one', {
                    id: this.localModel.updated_by,
                    fields: 'name'
                })

                if (!success)
                    throw new Error(error)

                this.userUpdateBy = data
            } catch (e) {
                console.error(e)
            }
        },
        async returnToRevision (report) {
            report.status = 'processing'
            this.updateReport()
        },
        async updateReport () {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/update', this.localModel)
                this.$emit('updated', {...this.localModel})
            } catch (e) {
                console.error(e)
            }
            finally {
                this.localDisabled = false
            }
        },
        async removeReport (reportId) {
            try {
                this.localDisabled = true
                await this.$store.dispatch('fioko_report/delete', { id: reportId })
                this.$emit('removed', reportId)
            } catch (e) {
                throw new Error(e)
            } finally {
                this.localDisabled = false
            }
        },
    }
}
</script>