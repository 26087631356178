<template>
    <div>
        <v-btn
            text
            :disabled="disabled"
            @click="show"
        >
            <slot>Выберите</slot>
        </v-btn>
        <v-menu
            v-model="showMenu"
            absolute
            offset-y
            :position-x="x"
            :position-y="y"
            :close-on-content-click="false"
        >
            <v-card class="pa-4">
                
                <div class="d-flex">
                    <v-list v-if="filter" dense>
                        <v-subheader>{{ filterLabel }}</v-subheader>
                        <v-list-item-group
                            v-model="filterItemIndex"
                            color="primary"
                        >
                            <v-list-item v-for="(item, i) in filter" :key="i">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>

                    <v-divider vertical class="mx-2" />

                    <v-list dense>
                        <v-subheader>{{ itemsLabel }}</v-subheader>
                        <v-list-item-group :value="filteredItems.findIndex(fi => fi.value === value)" color="primary">
                            <v-list-item
                                v-for="(item, i) in filteredItems"
                                :key="i"
                                @click="onItemClick(item.value)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    <template v-if="item.subtext">
                                        <v-divider class="my-2" />

                                        <v-subheader style="height: 20px;">{{ item.subtext }}</v-subheader>
                                    </template>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: { type: [Number, String], default: null },
        filterLabel: { type: String, default: 'Фильтр' },
        filterKey: { type: String, default: 'filterKey' },
        filter: { type: Array, default: null },
        filterFunction: { type: Function, default: null },
        itemsLabel: { type: String, default: null },
        items: { type: Array, required: true },
        disabled: { type: Boolean, default: false },
        defaultFilter: { type: [String, Number], default: null }
    },
    data () {
        return {
            filterItemIndex: null,
            showMenu: false,
            x: 0,
            y: 0,
        }
    },
    computed: {
        filterItemValue () {
            return this.filter[this.filterItemIndex]?.value
        },
        filteredItems () {
            return this.filterItemValue ?
                        (
                            this.filterFunction ?
                            this.items.filter(item => this.filterFunction(item, this.filterItemValue)) :
                            this.items.filter(item => item[this.filterKey] === this.filterItemValue)
                        ) :
                    this.items
        }
    },
    created () {
        if (this.defaultFilter) {
            const defaultFilterIndex = this.filter.findIndex(item => item.value === this.defaultFilter)
            if (defaultFilterIndex > -1)
                this.filterItemIndex = defaultFilterIndex
        }
    },
    methods: {
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        onItemClick (value) {
            this.$emit('change', value)
            this.showMenu = false
        }
    }
}
</script>