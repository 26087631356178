<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack, fetchModel }"
        title="Форма сбора данных ФИС ОКО"
        update
        url-update="/fioko-data-collection/update/:id"
        url-back="/fioko-data-collection"
        action-one="fioko_data_collection/one"
        :fields="fields"
    >
        <fioko-data-collection-form :model="model" @success="onSuccess" @back="onBack" @update:report="fetchModel" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import FiokoDataCollectionForm from '@/components/forms/FiokoDataCollectionForm'

export default {
    name: 'FiokoDataCollectionUpdate',
    components: { FormView, FiokoDataCollectionForm },
    computed: {
        fields () {
            return [ '*' ]
        }
    }
}
</script>