<template>
    <v-form>
        <action-buttons hide-save-btn @back="back" />

        <v-divider class="my-4" />

        <report-instruction :expanded.sync="reportInstractionExpanded" class="mb-4" />
        
        <fioko-action-buttons
            :disabled-create-btn="loading.creatingReport || loading.dataCollections || loading.creatingResult || loading.forms || loading.reports"
            :hide-create-btn="loading.reports"
            :create-btn-loading-state="loading.creatingResult"
            :all-selected-reports-has-done-status="allSelectedReportsHasDoneStatus"
            class="mb-5"
            @click:createReport="createReport"
            @click:preview="dialogs.preview = true"
            @click:createResult="createResultButtonHandler"
        />

        <v-progress-linear
            v-if="loading.reports"
            color="purple darken-2"
            indeterminate
        />
        <v-alert
            v-else-if="error"
            dense
            type="error"
        >
            {{ error }}
        </v-alert>

        <div class="d-flex justify-start align-center py-0 mb-5">
            <span class="d-flex align-center mr-5"><v-subheader>Название</v-subheader> {{ model.name }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Начало</v-subheader> {{ getReadableDate(model.start_at) }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Завершение</v-subheader> {{ getReadableDate(model.end_at) }}</span>
            <span class="d-flex align-center mr-5"><v-subheader>Статус</v-subheader> <v-chip :color="statusColor" dark>{{ model.status_formatted }}</v-chip></span>
        </div>

        <report-form
            v-for="report in reports"
            :key="`${report.id}_${report.status}`"
            :report="report"
            :teachers="teachers"
            :groups="filteredGroups"
            :selected="selectedReportIds.includes(report.id)"
            :disabled="loading.reports"
            :default-subject="model.subject"
            :edit-button-loading="loading.editButton"
            class="mb-5"
            @updated="onReportUpdated"
            @removed="fetchReports"
            @update:select="onReportSelectChange($event, report.id)"
            @click:edit="onReportEditButtonClick"
        />

        <v-subheader v-if="!reports.length && !loading.reports">По данной форме сбора данных не назначены отчёты на преподавателей.</v-subheader>
        
        <fioko-action-buttons
            :disabled-create-btn="loading.creatingReport || loading.dataCollections || loading.creatingResult || loading.forms || loading.reports"
            :hide-create-btn="loading.reports"
            :create-btn-loading-state="loading.creatingResult"
            :all-selected-reports-has-done-status="allSelectedReportsHasDoneStatus"
            class="mb-5"
            @click:createReport="createReport"
            @click:preview="dialogs.preview = true"
            @click:createResult="createResultButtonHandler"
        />

        <v-divider class="my-4" />

        <action-buttons hide-save-btn @back="back" />

        <v-alert v-if="error" type="error" class="mt-5">
            {{ error }}
        </v-alert>

        <v-dialog
            v-if="isCanEditFiokoDataCollectionSection"
            v-model="dialogs.preview"
            persistent
            max-width="1300px"
        >
           
            <v-card v-if="dialogs.preview" class="pa-2">
                <v-card-actions class="pa-0">
                    <v-container>
                        <v-row class="py-0">
                            <v-col class="d-flex align-center py-0">
                                <v-switch
                                    v-model="editMode"
                                    color="purple darken-2"
                                    label="Редактировать"
                                    hide-details
                                    dense
                                ></v-switch>
                                <v-spacer></v-spacer>
                                <v-btn outlined small @click.prevent.stop="closePreviewHandler">
                                    Закрыть
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
                <v-card-text class="pa-0">
                    <preview
                        :title="previewTitle"
                        :reports="selectedReports"
                        :group-parallel-number="groupParallelNumber"
                        :disabled="!editMode"
                        @updated="onReportUpdatedInPreview"
                        @change="previewHasUnsavedChanges = true"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import ActionButtons from '@/components/crud/ActionButtons.vue'
import ReportForm from './ReportForm.vue'
import Preview from './Preview.vue'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import FiokoDataCollectionMixin from '@/mixins/FiokoDataCollectionMixin'
import FiokoActionButtons from './ActionButtons.vue'
import ReportInstruction from '@/components/fioko-data-collection/ReportInstruction.vue'

export default {
    components: { ActionButtons, ReportForm, Preview, FiokoActionButtons, ReportInstruction },
    mixins: [isUserCanMixin, FiokoDataCollectionMixin],
    props: {
        model: { type: Object }
    },
    data () {
        return {
            infoExpanded: false,
            editMode: false,
            previewHasUnsavedChanges: false,
            reportInstractionExpanded: false,
            loading: {
                dataCollections: false,
                reports: false,
                creatingResult: false,
                editButton: false
            },
            dialogs: {
                preview: false
            },
            selectedReportIds: [],
            reports: [],
            groups: [],
            teachers: [],
        }
    },
    async created () {
        this.loading.reports = true
        await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        const results = await Promise.all([this.fetchGroups(), this.fetchTeachers()])
        this.loading.reports = false

        const error = results.find(result => typeof result === 'string')
        if (error) {
            this.error = error
            return
        }

        await this.fetchReports()

        if (!this.reports.length && this.model.grade) {

            await this.fillWithReports()
            await this.fetchReports()
        }
    },
    computed: {
        statusColor () {
            const statusToColor = {
                done: 'green',
                default: 'grey'
            }
            return statusToColor[this.model.status] || statusToColor.default
        },
        filteredGroups () {
            const usedGroupsInReports = this.reports.map(r => r.group_id)
            return this.groups.map(g => ({
                ...g,
                disabled: usedGroupsInReports.includes(g.value)
            }))
        },
        subjects () { return this.$store.getters['calendar_event/subjects'] },
        jsonForm () {

            const jsonForm = typeof this.model.json_template === 'string' ? JSON.parse(this.model.json_template) : this.model.json_template

            return JSON.stringify({
                template: jsonForm.data.template,
                handbooks: typeof this.model.handbooks === 'string' ? JSON.parse(this.model.handbooks) : this.model.handbooks
            })
        },
        allSelectedReportsHasDoneStatus () {
            if (!this.selectedReports.length)
                return false

            return this.selectedReports.length && this.selectedReports.every(report => report.status === 'done')
        },
        selectedReports () {
            return this.reports.filter(report => this.selectedReportIds.includes(report.id))
        },
        previewTitle () {

            const subject = this.model.subject || this.selectedReports[0].subject
            const groups = Array.from( new Set(
                                        this.selectedReports.map(r => r.group_id)
                                            .map(group_id => this.groups.find(g => g.value === group_id)?.text)
                                            .filter(v => !!v)
                            ))
            if (groups.length) {
                return `${subject}: ${groups.join(', ')} ${groups.length > 1 ? 'классы' : 'класс'}`
            }

            return subject
        },
        groupParallelNumber () {
            if (!this.selectedReports.length)
                return null

            const groupId = this.selectedReports[0].group_id
            
            const groupIndex = [...this.groups]
                                .sort((a, b) => a.value - b.value)
                                .findIndex(group => group.value === groupId)
            return groupIndex + 1
        },
    },
    methods: {
        back () {
            return this.$emit('back', 1);
        },
        async createResultButtonHandler (format) {
            const typeToMethod = {
                xlsx: this.exportToXlsxHandler,
                json: this.exportToJsonHandler
            }
            typeToMethod[format]?.()
        },
        async fillWithReports () {

            this.loading.reports = true
            try {
                for (const group of this.groups) {
                    const newReport = this.getReportObject({ group_id: group.value })
                    await this.$store.dispatch('fioko_report/create', newReport)
                }
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.reports = false
            }
        },
        async exportToXlsxHandler () {
            try {
                this.loading.creatingResult = true
                const succeed = await this.createXlsx(this.selectedReportIds, this.model.data_collection_id)
                if (!succeed)
                    throw new Error(this.error)

                await this.downloadGeneratedFile(this.model.data_collection_id, this.model.name)
            } catch (e) {
                    console.error(e)
            } finally {
                this.loading.creatingResult = false
            }
        },
        exportToJsonHandler () {
            
            if (!(this.selectedReportIds.length && this.allSelectedReportsHasDoneStatus)) { return false }

            const reports = this.reports.filter(r => this.selectedReportIds.includes(r.id))
            this.exportToJson(reports, this.model.name)
        },
        exportToXmlHandler () {

            if (!(this.selectedReportIds.length && this.allSelectedReportsHasDoneStatus)) { return false }

            const reports = this.reports.filter(r => this.selectedReportIds.includes(r.id))
            this.exportToXml(reports, this.model.name)
        },
        async fetchGroups () {
            try {
                const { data } = await this.$store.dispatch('group/list', {
                            pagination: 0,
                            fields: 'id,name,grade'
                        });
                this.groups = data.items?.filter(item => item.grade === (this.model.grade || item.grade))
                                            .map(item => ({
                                                text: item.name,
                                                value: item.id
                                            }))
                return true
            } catch (e) {
                console.error(e)
                return e.message || 'Неизвестная ошибка загрузки данных'
            }
        },
        async fetchTeachers () {
            try {
                const { data } = await this.$store.dispatch('user/list', {
                            pagination: 0,
                            filter: { active: 1, role: { in: ['admin_vpr', 'teacher']} },
                            fields: 'id,name,subjects,role'
                        });

                this.teachers = data?.items
                        .map(item => {
                            return {
                                text: item.name,
                                value: item.id,
                                role: item.role,
                                subjects: item.role === 'admin_vpr' ? this.subjects.map(s => s.value) : item.subjects.map(s => s.subject)
                            }
                        })
                return true
            } catch (e) {
                console.error(e)
                return e.message || 'Неизвестная ошибка загрузки данных'
            }
        },
        async fetchReports () {
            
            try {
                this.reports = []
                this.error = null
                this.loading.reports = true
                const { data: reports } = await this.$store.dispatch('fioko_report/list', {
                    fields: 'updated_by,value,teacher_id,group_id,status,subject',
                    filter: { data_collection_id: this.model.data_collection_id }
                })
                
                this.reports = reports?.items || []
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.reports = false
            }
        },
        async fetchOneReport (id) {
            
            try {
                this.error = null
                this.loading.reports = true
                const { success, data, error } = await this.$store.dispatch('fioko_report/one', {
                    id,
                    fields: 'updated_by,value,teacher_id,group_id,status,subject'
                })

                if (!success)
                    throw new Error(error)

                const ri = this.reports.findIndex(r => r.id === id)
                this.reports[ri] = data
                this.reports = [...this.reports]
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.reports = false
            }
        },
        async createReport () {
            
            this.loading.creatingReport = true
            try {
                const newReport = this.getReportObject()

                await this.$store.dispatch('fioko_report/create', newReport)
                this.fetchReports()
            } catch (e) {
                console.error(e)
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
            } finally {
                this.loading.creatingReport = false
            }
        },
        getReadableDate (timestamp) {
            return this.$moment(timestamp, 'X').format('DD.MM.YYYY')
        },
        getReportObject (extraFields = {}) {
            return {
                    data_collection_id: this.model.data_collection_id,
                    form_template_id: this.model.form_template_id,
                    teacher_id: null,
                    group_id: null,
                    subject: this.model.subject,
                    value: this.jsonForm,
                    status: 'waiting',
                    ...extraFields
                }
        },
        onReportSelectChange ({ value, reportId }) {
            if (value)
                this.selectedReportIds.push(reportId)
            else
                this.selectedReportIds = this.selectedReportIds.filter(id => reportId !== id)
        },
        async onReportEditButtonClick (report) {
            this.loading.editButton = true
            await this.fetchOneReport(report.id)
            this.selectedReportIds = [report.id]
            this.editMode = true
            this.dialogs.preview = true
            this.loading.editButton = false
        },
        onReportUpdated (report) {
            const group = this.groups.find(g => g.value === report.group_id)
            const teacher = this.teachers.find(t => t.value === report.teacher_id)
            const ri = this.reports.findIndex(r => r.id === report.id)
            this.reports[ri] = report
            this.reports = [...this.reports]

            this.$emit('update:report')
            this.$root.$emit('snack-bar', { text: `Заполнение отчета за ${group.text}, по "${this.model.name}" назначен на ${teacher.text}`, timeout: 5 * 1000})
        },
        onReportUpdatedInPreview (report) {
            const reportIndex = this.reports.findIndex(r => r.id === report.id)
            this.reports[reportIndex] = report
            this.reports = [...this.reports]
            this.previewHasUnsavedChanges = false
        },
        closePreviewHandler () {
            if (this.previewHasUnsavedChanges) {

                const confirmed = confirm('Все несохраненные изменения будут утеряны. Вы уверены?')
                
                if (!confirmed) return
            }
            this.editMode = false
            this.dialogs.preview = false
            this.$emit('update:report')
        }
    },
    watch: {
        'dialogs.preview' (value) {
            if (!value)
                this.selectedReportIds = []
        },
        error (newValue, oldValue) {
            if (!oldValue && newValue) {
                this.$nextTick().then(() => {
                    window.scrollTo(0, window.document.body.clientHeight || 999999)
                })
            }
        }
    }
}
</script>