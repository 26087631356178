<template>
    <v-container>
        <v-row>
            <v-col>
                <h2 v-if="title" class="mb-4">{{ title }}</h2>
                <div class="fioko-report--detail">

                    <template v-if="formModel">

                        <v-progress-linear
                            v-if="!formModel || !formModel.length"
                            :size="50"
                            color="purple darken-2"
                            indeterminate
                        />
                        <template v-else>
                            <v-tabs v-model="currentTab" class="mb-2">  
                                <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
                            </v-tabs>
                            <report-detail-table
                                v-show="currentTab === 0"
                                :key="0"
                                :model="classesModel"
                                table-name="Классы"
                                :disabled="disabled || saving"
                                @save="(payload) => runSaveFunctionInQueue(() => savePage(payload, 'Классы', 'Классы'))"
                                @change="onReportChanged"
                            />
                            <report-detail-table
                                v-show="currentTab === 1"
                                :key="1"
                                :model="formModel"
                                :handbooks="handbooks"
                                :disabled="disabled || saving"
                                table-name="Протокол"
                                @save="(payload) => runSaveFunctionInQueue(() => savePage(payload, 'Протокол', 'Код'))"
                                @change="onReportChanged"
                            />
                        </template>
                    </template>
                    
                    <v-alert
                        v-else-if="error"
                        dense
                        type="error"
                        class="rounded-10"
                    >
                        {{ error }}
                    </v-alert>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ReportDetailTable from './ReportDetailTable'

export default {
    components: { ReportDetailTable },
    props: {
        reports: { type: Array, required: true },
        groupParallelNumber: { type: Number, default: null },
        title: { type: String, default: null },
        disabled: { type: Boolean, default: false }
    },
    data () {
        return {
            saving: false,
            error: null,
            formModel: [],
            classesModel: [],
            handbooks: [],
            students: [],
            classesAndBooks: [],
            unknownBook: {
                name: null,
                author: null
            },
            availableBooks: [],
            currentTab: 1,
            tabs: ['Классы', 'Протокол'],
            saveFunctionsQueue: []
        }
    },
    async created () {
        const { success: s1, data: d1, error: e1 } = await this.$store.dispatch(
                                                        'group/list',
                                                        {
                                                            filter: { id: { in: this.reports.map(r => r.group_id) } },
                                                            pagination: 0,
                                                            fields: 'student_id'
                                                        }
                                                    )
        if (!s1)
            throw new Error(e1)

        const user_ids = d1.items.map(item => item.student_id).flat()
        const { success: s2, data: d2, error: e2 } = await this.$store.dispatch('user/list', {
                                        filter: { id: { in: user_ids } },
                                        pagination: 0,
                                        fields: 'id,name,fioko_id,gender'
                                    })
        if (!s2)
            throw new Error(e2)

        this.students = d2.items
        
        this.initFormModels()
    },
    methods: {
        async runSaveFunctionInQueue (func) {
            if (!func) {
                this.saveFunctionsQueue = []
                return
            }

            this.saveFunctionsQueue.push(func)

            if (this.saveFunctionsQueue.length <= 1)
                return
            
            let funcInQueue = this.saveFunctionsQueue.shift()
            let everythingSucceed = true
            while (funcInQueue) {
                const succeed = await funcInQueue()

                if (!succeed) {
                    this.saveFunctionsQueue = []
                    everythingSucceed = false
                    break
                }

                funcInQueue = this.saveFunctionsQueue.shift()
            }
            
            if (everythingSucceed)
                    this.$root.$emit('snack-bar', { text: 'Отчёт успешно сохранён'})
        },
        clearUnknownBook () {
            this.unknownBook.name = null
            this.unknownBook.author = null
        },
        initFormModels () {
            for (const report of this.reports) {
                
                const model = JSON.parse(report.value)

                // Восстанавливаем состояние по учебникам
                const groupSheet = model.template.find(sheet => sheet.name === 'Классы')
                const protocolSheet = model.template.find(sheet => sheet.name === 'Протокол')

                const cellForBook = groupSheet?.cells?.find(cell => cell.code === 'C$2')

                this.availableBooks.push(...(cellForBook?.list || []))
                this.handbooks.push(...(model.handbooks || []))

                if (!this.formModel.length)
                    this.formModel.push(...protocolSheet.cells) // Модель таблицы Протокол
                else
                    protocolSheet.cells.forEach(cell => {
                        const modelCell = this.formModel.find(modelCell => modelCell.name === cell.name)
                        modelCell.values.unshift(...cell.values)
                    })

                if (!this.classesModel.length) {

                    this.classesModel.push(...groupSheet.cells) // Модель таблицы Классы
                    const classesCell = this.classesModel.find(cell => cell.code === 'C$1')
                    const bookCell = this.classesModel.find(cell => cell.code === 'C$2')
                    const authorCell = this.classesModel.find(cell => cell.code === 'C$3')
                    const bookTitleCell = this.classesModel.find(cell => cell.code === 'C$4')
                    
                    if (classesCell)
                        classesCell.infoType = true

                    if (bookCell)
                        bookCell.selectMode = true
                    
                    if (authorCell)
                        authorCell.emptyAvailableValues = true
                    
                    if (bookTitleCell)
                        bookTitleCell.emptyAvailableValues = true
                    
                    // Задаем размер таблицы
                    this.classesModel.forEach(col => {
                        if (!Array.isArray(col.values))
                            col.values = new Array(this.reports.length).fill(null)
                    })

                    // Устанавливаем номер класса
                    if (this.groupParallelNumber) {

                        const groupColumn = this.classesModel.find(col => col.name.includes('Класс'))
                        groupColumn.values = groupColumn.values.fill(this.groupParallelNumber)
                    }
                }
                else
                    groupSheet.cells.forEach(cell => {
                        const modelCell = this.classesModel.find(modelCell => modelCell.name === cell.name)
                        modelCell.values.unshift(...cell.values)
                    })
            }

            // Добавляем временные столбцы, которые не попадут в результат
            const temporaryColumns = []

            temporaryColumns.push({
                temporary: true,
                code: 'T$1',
                name: '№',
                values: this.students.map((s, i) => i + 1)
            })

            temporaryColumns.push({
                temporary: true,
                code: 'T$2',
                name: 'Ученик',
                values: this.students.map(s => s.name)
            })
            this.formModel.unshift(...temporaryColumns)
            // Временные столбцы - конец
            if (Array.isArray(this.formModel[this.formModel.length - 1].values) && this.formModel[this.formModel.length - 1].values.length)
                return

            this.setInitialValues(this.formModel)
        },
        setInitialValues (form) {
            // Задаем размер таблицы
            form.forEach(col => {
                if (!Array.isArray(col.values) || !col.values.length)
                    col.values = new Array(this.students.length).fill(null)
            })
            // Устанавливаем коды учеников
            const codeColumn = form.find(col => col.name === 'Код')
            if (codeColumn)
                codeColumn.values = this.students.map(s => s.fioko_id)

            // Устанавливаем пол учеников
            const genderColumn = form.find(col => col.name === 'Пол')
            const genderList = {
                default: null,
                male: 'м',
                female: 'ж'
            }
            if (genderColumn)
                genderColumn.values = this.students.map(s => genderList[s.gender] || genderList.default)

            // Устанавливаем номер класса
            if (this.groupParallelNumber) {
                
                const groupColumn = form.find(col => col.name.includes('Класс'))
                groupColumn.values = groupColumn.values.fill(this.groupParallelNumber)
                groupColumn.emptyAvailableValues = true
            }
        },
        onReportChanged () {
            this.$emit('change')
        },
        async savePage (payload, sheetName, primaryColName) {
            try {

                this.saving = true

                for (const report of this.reports) {
                    

                    const model = JSON.parse(report.value)

                    const sheet = model.template.find(sheet => sheet.name === sheetName)

                    if (!sheet) {
                        console.error(`Can't find worksheet with name "${sheetName}"`)
                        continue
                    }
                    if (!Array.isArray(sheet.cells[sheet.cells.length - 1].values) || !sheet.cells[sheet.cells.length - 1].values.length)
                        this.setInitialValues(sheet.cells)

                    const primaryValues = sheet.cells.find(column => column.name === primaryColName)?.values

                    if (!primaryValues?.length) {
                        console.error(`Can't find column with name "${primaryColName}"`)
                        continue
                    }

                    const changedRowsIndexes = []

                    if (!payload) throw new Error()

                    payload.model.find(column => column.name === primaryColName)?.values?.forEach((payloadPrimary, index) => {
                        if (primaryValues.includes(payloadPrimary))
                            changedRowsIndexes.push(index)
                    })

                    if (!changedRowsIndexes?.length) {
                        console.error('Can\'t find changed rows')
                        continue
                    }

                    for (const column of sheet.cells) {
                        const payloadColumn = payload.model.find(pcolumn => pcolumn.name === column.name)
                        if (!payloadColumn) {
                            console.error(`Can't find column with name ${column.name} in payload object`)
                            continue
                        }
                        const pickedValues = changedRowsIndexes.map(i => payloadColumn.values[i])
                        column.values = pickedValues
                    }
                    report.value = JSON.stringify(model)
                    report.status = this.saveFunctionsQueue.length === 0 ? 'done' : 'processing'
                    const { success, error } = await this.$store.dispatch('fioko_report/update', report)

                    if (!success)
                        throw new Error(error[Object.keys(error)[0]][0])

                    this.$emit('updated', report)
                }
                return true
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Ошибка: ' + (e.message || 'Невозможно сохранить отчёт с ошибками')})
                return false
            } finally {
                this.saving = false
            }
        }
    }
}
</script>