<template>
    <div class="d-flex align-center">
        <v-btn
            v-if="!hideCreateBtn"
            text color="purple"
            :disabled="disabledCreateBtn"
            @click="$emit('click:createReport')"
        >+ Назначить</v-btn>

        <v-spacer />

        <v-menu offset-y v-if="exportWays.length">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :disabled="createBtnLoadingState || !allSelectedReportsHasDoneStatus"
                    color="purple"
                    text
                    v-bind="attrs"
                    v-on="on"
                >
                    Экспорт
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in exportWays"
                    :key="index"
                    @click="item.action"
                >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn
            :disabled="createBtnLoadingState || !allSelectedReportsHasDoneStatus"
            text color="primary"
            @click="$emit('click:preview')"
        >Предпросмотр</v-btn>

        <v-btn
            text
            color="primary"
            :disabled="!allSelectedReportsHasDoneStatus"
            @click="dialogs.exportReport = true"
        >Экспорт отчёта</v-btn>
        <v-dialog v-model="dialogs.exportReport" :persistent="createBtnLoadingState" max-width="600">
            <v-card>
                <v-toolbar dark color="purple darken-2">
                    <v-toolbar-title>Экспорт отчёта</v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                    <div class="d-flex flex-column align-center justify-center w-100 my-5">
                        <v-btn :disabled="createBtnLoadingState" color="primary" class="mb-4" @click.prevent.stop="$emit('click:createResult', 'json')">
                            Отчёт для передачи в ФИС ОКО
                        </v-btn>
                        <v-btn :loading="createBtnLoadingState" color="primary" @click.prevent.stop="$emit('click:createResult', 'xlsx')">
                            Отчёт в формате XlSX
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        hideCreateBtn: { type: Boolean, default: false },
        disabledCreateBtn: { type: Boolean, default: false },
        createBtnLoadingState: { type: Boolean, default: false },
        allSelectedReportsHasDoneStatus: { type: Boolean, default: false },
        exportWays: { type: Array, default: () => ([]) }
    },
    data () {
        return {
            dialogs: {
                exportReport: false
            }
        }
    },
    watch: {
        createBtnLoadingState (newValue, oldValue) {
            if (oldValue === true && newValue === false)
                this.dialogs.exportReport = false
        }
    }
}
</script>